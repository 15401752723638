import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import "./App.css";

const Home = () => {
  return (
    <div>
      <h1>Home Page</h1>
      <p>Welcome to the Home Page!</p>
    </div>
  );
};

const About = () => {
  return (
    <div>
      <h1>About Page</h1>
      <p>This is the About Page.</p>
    </div>
  );
};

const Library = () => {
  const ImageComponent = ({ index }) => {
    return (
      <div style={{margin: "1rem"}}>
        <img
        src="https://sample-img.lb-product.com/wp-content/themes/hitchcock/images/100MB.png"
        alt={`img${index}`}
      />
      </div>
    );
  };

  return (
    <div>
      <h1>Library Page</h1>
      <p>This is the Library Page.</p>
      <div>{Array.from(Array(10).keys()).map((index) => {
        return <ImageComponent key={index} index={index} />;
      })}</div>
    </div>
  );
};

function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <nav>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about">About</Link>
              </li>
              <li>
                <Link to="/library">Library</Link>
              </li>
            </ul>
          </nav>
        </header>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/library" element={<Library />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
